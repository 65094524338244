import localeAntd from 'antd/es/locale/id_ID'

import enUS from './en-US'

const messages = {
  ...enUS.messages,
  'actions.back': 'Kembali',
  'actions.cancel': 'Batal',
  'actions.save': 'Simpan',
  'actions.search': 'Cari',
  'actions.update': 'Ubah',
  'auth.forgot': 'Lupa Password',
  'auth.profile': 'Profil',
  'auth.profile.update': 'Ubah Profil',
  'auth.signup': 'Registrasi',
  'auth.signup.hint': 'Belum punya akun?',
  'auth.fields.name': 'Nama',
  'auth.fields.phone': 'Telepon',
  'breadcrumb.list': 'Daftar',
  'breadcrumb.create': 'Tambah',
  'breadcrumb.update': 'Ubah',
  'breadcrumb.detail': 'Detil',
  component: 'Komponen',
  'component.list': 'Daftar Komponen',
  dashboard: 'Beranda',
  news: 'Berita',
  'topBar.profileMenu.hello': 'Halo, {name}',
  training: 'Pelatihan',
  'training.join': 'Ikut',
  'training.joinNew': 'Ikut Pelatihan Baru',
  'training.recent': 'Pelatihan Terakhir',
}

export default {
  locale: 'id-ID',
  localeAntd,
  messages,
}
