import querystring from 'querystring'
import React, { Fragment } from 'react'
import { notification } from 'antd'

import { store as reduxStore } from 'index'
import actions from 'redux/user/actions'
import { t } from './string'

const fetchWithTimeOut = (url, options, timeout = 30000) => {
  return Promise.race([
    window.fetch(url, options),
    new Promise((resolve, reject) =>
      setTimeout(() => reject(new Error(t('res.errors.timeout'))), timeout),
    ),
  ])
}

const apiUrl = process.env.REACT_APP_API_BASE_URL

export const request = async (options, forced = false) => {
  const headers = new Headers({
    'Content-Type': 'application/json',
  })

  if (options.url !== 'core/auth/user/login' && localStorage.getItem('mAuthUser')) {
    const authUser = JSON.parse(localStorage.getItem('mAuthUser'))
    headers.append('Authorization', `Token ${authUser.token}`)
  }

  const defaults = { headers }
  options = Object.assign({}, defaults, options)
  if (options.method === 'GET') {
    options.url = `${options.url}?${querystring.stringify(options.body)}`
    delete options.body
  } else {
    if (!options.method && options.body) {
      options.method = 'POST'
    }
    if (options.body) options.body = JSON.stringify(options.body)
  }

  try {
    const response = await fetchWithTimeOut(`${apiUrl}/${options.url}`, options)
    const json = await response.json()
    if (!response.ok || json.error || json.error_desc) {
      const msg = json.error ? json.error.message || json.error : json.error_desc
      if (msg === 'jwt expired') reduxStore.dispatch({ type: actions.LOGOUT })
      else if (msg) throw new Error(msg)
    }
    return json
  } catch (error) {
    notification.error({ message: 'Error', description: error.message })
    if (!forced) throw error
  }
  return null
}

export const serverUrl = url => `${apiUrl}/${url}`

export const hiddenInput = params => {
  const authUser = JSON.parse(localStorage.getItem('mAuthUser'))
  return (
    <Fragment>
      <input type='hidden' name='token' value={authUser.token} />
      <input type='hidden' name='params' value={JSON.stringify(params)} />
    </Fragment>
  )
}
