import React, { Fragment, useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { reduce } from 'lodash'

import { StringUtils } from 'utils'

import styles from './style.module.scss'

const { t } = StringUtils

const mapStateToProps = ({ menu, settings }) => ({
  logo: settings.logo,
  menuData: menu.menuData,
})

const Breadcrumbs = (props) => {
  const [breadcrumbs, setBreadcrumbs] = useState([])

  const {
    location: { pathname },
    logo,
    menuData = [],
  } = props

  useEffect(() => {
    setBreadcrumbs(() => getBreadcrumbs())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menuData])

  const getPath = (data, url, parents = []) => {
    const items = reduce(
      data,
      (result, entry) => {
        if (result.length) {
          return result
        }
        if (entry.url === url) {
          return [entry].concat(parents)
        }
        if (entry.children) {
          const nested = getPath(entry.children, url, [entry].concat(parents))
          return (result || []).concat(nested.filter((e) => !!e))
        }
        return result
      },
      [],
    )
    return items.length > 0 ? items : [false]
  }

  const toUpper = (str) => str.replace(/\b\w/g, (l) => l.toUpperCase())

  const wrapItem = ({ key, item, to, isCurrent }) => {
    return (
      <li className={styles.breadcrumb} key={key}>
        {isCurrent && <strong className={styles.breadcrumbLink__current}>{toUpper(item)}</strong>}
        {!isCurrent && to && (
          <Link to={to} className={styles.breadcrumbLink}>
            {toUpper(item)}
          </Link>
        )}
        {!isCurrent && !to && <span>{toUpper(item)}</span>}
      </li>
    )
  }

  const getBreadcrumbs = () => {
    const [activeMenuItem] = getPath(menuData, pathname)
    const pathUrl = pathname.split('/')

    if (pathUrl.length > 1) {
      let hasDetail = false
      return pathUrl.map((item, index) => {
        const isCurrent = index === pathUrl.length - 1
        const to = isCurrent ? undefined : [...pathUrl].splice(0, index + 1).join('/')
        if (index === 0) {
          return null
        }
        if (activeMenuItem) {
          if (isCurrent) {
            return wrapItem({
              key: item,
              item: ['list', 'create'].includes(item)
                ? t(`breadcrumb.${item}`)
                : activeMenuItem.title,
              isCurrent,
            })
          }
        }
        if (item.match(/^[0-9a-fA-F]{24}$/)) {
          hasDetail = true
          return wrapItem({
            key: item,
            item: t('breadcrumb.detail'),
            to,
            isCurrent,
          })
        }
        return wrapItem({
          key: item,
          item:
            (hasDetail && item === 'update') || item === 'create'
              ? t(`breadcrumb.${item}`)
              : t(item),
          to,
          isCurrent,
        })
      })
    }
    return wrapItem({ key: 'single', item: activeMenuItem.title, isCurrent: true })
  }

  return (
    <div className={styles.subbar}>
      <ul className={`${styles.breadcrumbs} mr-4`}>
        <li className={styles.breadcrumb}>
          <a href="#" className={styles.breadcrumbLink}>
            {logo}
          </a>
        </li>
        {breadcrumbs}
      </ul>
      {pathname === '/dashboard' && (
        <Fragment>
          <div className={`${styles.divider} mr-4 d-none d-xl-block`} />
          <button
            type="button"
            className="btn btn-primary btn-with-addon mr-auto text-nowrap d-none d-md-block"
          >
            <span className="btn-addon">
              <i className="btn-addon-icon fe fe-plus-circle" />
            </span>
            {t('training.joinNew')}
          </button>
        </Fragment>
      )}
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(Breadcrumbs))
