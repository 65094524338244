import localeAntd from 'antd/es/locale/en_US'

const messages = {
  'actions.back': 'Back',
  'actions.cancel': 'Cancel',
  'actions.create': 'Create',
  'actions.detail': 'Detail',
  'actions.reset': 'Reset',
  'actions.save': 'Save',
  'actions.search': 'Search',
  'actions.update': 'Update',
  'auth.forgot': 'Forgot Password',
  'auth.profile': 'Profile',
  'auth.profile.update': 'Update Profile',
  'auth.signin': 'Sign In',
  'auth.signout': 'Sign Out',
  'auth.signup': 'Sign Up',
  'auth.signup.hint': "Don't have an account?",
  'auth.fields.username': 'Username',
  'auth.fields.password': 'Password',
  'auth.fields.name': 'Name',
  'auth.fields.email': 'Email',
  'auth.fields.phone': 'Phone',
  'auth.fields.isActive': 'Status',
  'auth.fields.isActive.0': 'Inactive',
  'auth.fields.isActive.1': 'Active',
  'auth.fields.role': 'Role',
  'auth.fields.role.0': 'Public Visitor',
  'auth.fields.role.1': 'Superadmin',
  'auth.fields.role.2': 'Admin',
  'auth.fields.role.11': 'General User',
  'auth.res.success.signin': 'Logged In',
  'auth.res.success.signin.desc': 'You have successfully logged in!',
  'auth.res.success.signup': 'Succesful Registered',
  'auth.res.success.signup.desc': 'You have successfully registered!',
  'breadcrumb.list': 'List',
  'breadcrumb.create': 'Create',
  'breadcrumb.update': 'Update',
  'breadcrumb.detail': 'Detail',
  component: 'Component',
  'component.create': 'Create New Component',
  'component.detail': 'Component Detail',
  'component.list': 'Component List',
  'component.update': 'Update Component',
  'component.fields.name': 'Name',
  'component.fields.categories': 'Category',
  'component.fields.configuration': 'Configuration',
  'component.fields.description': 'Description',
  'component.fields.mainImage': 'Main Image',
  'component.fields.images': 'Other Images',
  'component.actions.addCategory': 'Add Category',
  dashboard: 'Dashboard',
  news: 'News',
  'res.errors.formValidation.required': 'This input field is required',
  'res.errors.formValidation.invalidEmail': 'Email address is not valid',
  'res.errors.timeout':
    'Something goes wrong with the request. Check your internet connection and try again later.',
  'res.errors.404': 'Page not found',
  'table.fields._i': 'No',
  'table.fields._blank': ' ',
  'table.fields._actions': 'Actions',
  'table.pagination.total':
    'Showing {start, number} to {end, number} of {total, number} {total, plural, one {entry} other {entries}}',
  'topBar.issuesHistory': 'Issues',
  'topBar.projectManagement': 'Projects',
  'topBar.typeToSearch': 'Search...',
  'topBar.findPages': 'Find pages...',
  'topBar.actions': 'Actions',
  'topBar.status': 'Status',
  'topBar.profileMenu.hello': 'Hello, {name}',
  training: 'Training',
  'training.join': 'Conduct',
  'training.joinNew': 'Conduct New Training',
  'training.recent': 'My Recent Trainings',
  create: 'My Recent Trainings',
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
