import { ApiUtils } from 'utils'

export async function login (username, password) {
  try {
    const result = await ApiUtils.request({
      url: 'core/auth/user/login',
      body: { user: { username, password } },
    })
    localStorage.setItem('mAuthUser', JSON.stringify(result.user))
    return result
  } catch (error) {} // eslint-disable-line
  return false
}

export async function currentAccount () {
  try {
    const authUser = JSON.parse(localStorage.getItem('mAuthUser'))
    if (authUser && authUser.token) return ApiUtils.request({ url: 'core/auth/user/current' })
  } catch (error) {} // eslint-disable-line
  return false
}

export async function logout () {
  localStorage.removeItem('mAuthUser')
}
