import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { history, store as reduxStore } from 'index'
import * as jwt from 'services/jwt'
import { StringUtils } from 'utils'
import menuActions from '../menu/actions'
import actions from './actions'

const { t } = StringUtils

const mapAuthProviders = {
  jwt: {
    login: jwt.login,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
}

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select((state) => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].login, email, password)
  if (success) {
    yield put({
      type: actions.LOAD_CURRENT_ACCOUNT,
    })
    yield history.push('/')
    notification.success({
      message: t('auth.res.success.signin'),
      description: t('auth.res.success.signin.desc'),
    })
  }
  if (!success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* REGISTER({ payload }) {
  const { email, password, name } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select((state) => state.settings)
  const success = yield call(mapAuthProviders[authProvider].register, email, password, name)
  if (success) {
    yield put({
      type: actions.LOAD_CURRENT_ACCOUNT,
    })
    yield history.push('/')
    notification.success({
      message: t('auth.res.success.signup'),
      description: t('auth.res.success.signup.desc'),
    })
  }
  if (!success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select((state) => state.settings)
  const response = yield call(mapAuthProviders[authProvider].currentAccount)
  if (response) {
    const { id, username, avatar, name, email, phone, roles } = response
    yield put({
      type: actions.SET_STATE,
      payload: {
        id,
        username,
        avatar,
        name,
        email,
        phone,
        roles: roles ? roles.sort((a, b) => a - b) : [],
        authorized: true,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  reduxStore.dispatch({ type: menuActions.GET_DATA })
}

export function* LOGOUT() {
  const { authProvider } = yield select((state) => state.settings)
  yield call(mapAuthProviders[authProvider].logout)
  yield put({
    type: actions.SET_STATE,
    payload: {
      id: '',
      username: '',
      avatar: '',
      name: '',
      email: '',
      phone: '',
      roles: [0],
      authorized: false,
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
