import React from 'react'
import { Menu, Dropdown } from 'antd'
import { connect } from 'react-redux'
import styles from './style.module.scss'

import FlagEn from './flags/en.svg'
import FlagId from './flags/id.svg'

const mapStateToProps = ({ settings }) => ({
  locale: settings.locale,
})

const LanguageSwitcher = ({ dispatch, locale }) => {
  const mapFlags = {
    en: FlagEn,
    id: FlagId,
  }

  const changeLanguage = ({ key }) => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'locale',
        value: key,
      },
    })
    setTimeout(() => {
      dispatch({ type: 'menu/GET_DATA' })
    }, 100)
  }

  const language = locale.substr(0, 2)

  const menu = (
    <Menu selectedKeys={[locale]} onClick={changeLanguage}>
      <Menu.Item key='en-US'>
        <span className={styles.menuIcon}>
          <img src={mapFlags.en} alt='English' />
        </span>
        English
      </Menu.Item>
      <Menu.Item key='id-ID'>
        <span className={styles.menuIcon}>
          <img src={mapFlags.id} alt='Indonesia' />
        </span>
        Indonesia
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']} placement='bottomRight'>
      <div className={styles.dropdown}>
        <div className={styles.flag}>
          <img src={mapFlags[language]} alt={language} />
          <span>{language}</span>
        </div>
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(LanguageSwitcher)
